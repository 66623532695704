<template>
  <div class="row">
    <div class="col-12">
      <!-- 개선 목록 -->
      <c-table
        ref="table"
        title="LBL0001174"
        tableId="impr01"
        :columns="gridImpr.columns"
        :data="riskReduce.imprs"
        :merge="gridImpr.merge"
        :gridHeight="setheight"
        @linkClick="linkClick"
      >
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='riskHazardPicture'">
            <q-btn 
              unelevated round dense
              size="7px"
              color="primary" 
              icon="wallpaper"
              @click.stop="openHazardPicture(props)" />
          </template>
          <template v-else-if="col.name==='picture'">
            <q-btn 
              unelevated round dense
              size="7px"
              color="primary" 
              icon="wallpaper"
              @click.stop="openImprPicture(props)" />
          </template>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'fm-impr',
  props: {
    riskReduce: {
      type: Object,
      default: function() {
        return {
          imprs: [],
          riskbooks: [],
        }
      },
    },
    param: {
      type: Object,
      default: () => ({
        vendorFlag: false,
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      gridImpr: {
        merge: [
          { index: 0, colName: 'processName' },
          { index: 1, colName: 'groupMdmSopId' },
          { index: 2, colName: 'ram4mRiskHazardClassGroup' },
          { index: 3, colName: 'ram4mAssessScenarioId' },
          { index: 4, colName: 'ram4mAssessScenarioId' },
          { index: 5, colName: 'ram4mAssessScenarioId' },
          { index: 6, colName: 'ram4mAssessScenarioId' },
          { index: 7, colName: 'ram4mAssessScenarioId' },
          { index: 8, colName: 'ram4mAssessScenarioId' },
        ],
        columns: [],
        data: [],
        height: '500px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      editable: true,
      imprData: null,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    setheight() {
      let pxRemove = this.height.replace('px', '');
      return this.height && this.height.indexOf('px') > -1 && !isNaN(pxRemove) ? (Number(pxRemove) - 60) + 'px' : '500px'
    }
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      if (!this.param.vendorFlag) {
        this.$comm.getStepItems('IBM_STEP_CD').then(_result => {
          this.gridImpr.columns = [
            {
              name: 'processName',
              field: 'processName',
              label: 'LBLPROCESS',
              align: 'left',
              style: 'width:100px',
              sortable: false,
            },
            {
              name: 'jobName',
              field: 'jobName',
              label: 'LBLJOB',
              align: 'left',
              style: 'width:150px',
              sortable: false,
            },
            {
              name: 'ram4mRiskHazardClassName',
              field: 'ram4mRiskHazardClassName',
              // 평가구분(4M)
              label: 'LBL0001112',
              align: 'center',
              style: 'width:90px',
              sortable: false,
            },
            {
              name: 'riskHazardPicture',
              field: 'riskHazardPicture',
              // 사진
              label: 'LBL0001175',
              align: 'center',
              style: 'width:40px',
              sortable: false,
              type: 'custom',
            },
            {
              name: 'riskHazardName',
              field: 'riskHazardName',
              // 유해위험요인
              label: 'LBL0001050',
              align: 'left',
              style: 'width:170px',
              sortable: false,
            },
            {
              name: 'relationLaw',
              field: 'relationLaw',
              // 관련법규
              label: 'LBL0001021',
              align: 'left',
              style: 'width:120px',
              sortable: false,
            },
            {
              name: 'damageTargetName',
              field: 'damageTargetName',
              // 피해대상
              label: 'LBL0001185',
              align: 'left',
              style: 'width:120px',
              sortable: false,
            },
            {
              name: 'damageTypeName',
              field: 'damageTypeName',
              // 재해형태
              label: 'LBL0001186',
              align: 'left',
              style: 'width:90px',
              sortable: false,
            },
            {
              name: 'existingRiskManagementActivities',
              field: 'existingRiskManagementActivities',
              // 현재안전조치
              label: 'LBL0001025',
              align: 'left',
              style: 'width:180px',
              sortable: false,
            },
            {
              name: 'picture',
              field: 'picture',
              // 개선<br/>전/후<br/>사진
              label: 'LBL0001176',
              align: 'center',
              type: 'custom',
              style: 'width:70px',
              sortable: false,
            },
            {
              name: 'addingRiskManagementActivities',
              field: 'addingRiskManagementActivities',
              // 추가 리스크관리 계획
              label: 'LBL0001122',
              align: 'left',
              style: 'width:180px',
              sortable: false,
            },
            {
              name: 'ibmTitle',
              field: 'ibmTitle',
              label: 'LBLTITLE',
              align: 'left',
              type: 'link',
              style: 'width:300px',
              sortable: true,
            },
            {
              name: 'ibmStepCd',
              field: 'ibmStepCd',
              label: 'LBLPROGRESS',
              align: 'center',
              style: 'width:100px',
              type: 'tag',
              colorItems: _result,
              sortable: false
            },
            {
              name: 'improveRequest',
              field: 'improveRequest',
              // 요청부서정보
              label: 'LBL0001016',
              align: 'center',
              style: 'width:250px',
              sortable: true,
            },
            {
              name: 'actionCompleteRequestDate',
              field: 'actionCompleteRequestDate',
              // 조치완료요청일
              label: 'LBL0001017',
              align: 'center',
              style: 'width:100px',
              sortable: true,
            },
            {
              name: 'actionDeptName',
              field: 'actionDeptName',
              // 조치부서
              label: 'LBL0001018',
              align: 'center',
              style: 'width:100px',
              sortable: true,
            },
          ]
        });
      } else {
        this.gridImpr.columns = [
          {
            name: 'processName',
            field: 'processName',
            label: 'LBLPROCESS',
            align: 'left',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: 'LBLJOB',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'ram4mRiskHazardClassName',
            field: 'ram4mRiskHazardClassName',
            // 평가구분(4M)
            label: 'LBL0001112',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'riskHazardName',
            field: 'riskHazardName',
            // 유해위험요인
            label: 'LBL0001050',
            align: 'left',
            style: 'width:170px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'relationLaw',
            field: 'relationLaw',
            // 관련법규
            label: 'LBL0001021',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'damageTargetName',
            field: 'damageTargetName',
            // 피해대상
            label: 'LBL0001185',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'damageTypeName',
            field: 'damageTypeName',
            // 재해형태
            label: 'LBL0001186',
            align: 'left',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'existingRiskManagementActivities',
            field: 'existingRiskManagementActivities',
            // 현재안전조치
            label: 'LBL0001025',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'picture',
            field: 'picture',
            // 개선<br/>전/후<br/>사진
            label: 'LBL0001176',
            align: 'center',
            type: 'custom',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'addingRiskManagementActivities',
            field: 'addingRiskManagementActivities',
            // 추가 리스크관리 계획
            label: 'LBL0001122',
            align: 'left',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: 'LBLTITLE',
            align: 'left',
            type: 'link',
            style: 'width:300px',
            sortable: true,
          },
          {
            name: 'stepFlagName',
            field: 'stepFlagName',
            label: 'LBLPROGRESS',
            align: 'center',
            style: 'width:100px',
            sortable: false
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            // 조치일
            label: 'LBL0000594',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ]
      }
      // list setting
    },
    research() {
      this.$emit('research');
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.imprData = row;
      this.popupOptions.title = 'LBLIMPROVE';
      if (!this.param.vendorFlag) {
        this.popupOptions.param = {
          sopImprovementId: row.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      } else {
        this.popupOptions.param = {
          ram4mAssessImprId: row.ram4mAssessImprId,
        };
        this.popupOptions.target = () => import(`${'@/pages/ram/4m/risk/4mImprRiskReduceDetail.vue'}`);
      }
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        this.$_.extend(this.imprData, result.col1)
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        this.research();
      }
    },
    openHazardPicture(props) {
      this.popupOptions.title = 'LBL0001110'; // 유해위험요인 사진
      this.popupOptions.param = {
        ramRiskHazardId: props.row.ramRiskHazardId,
        disabled: true
      };
      this.popupOptions.target = () => import(`${'@/pages/common/hazard/riskHazardPicture.vue'}`);
      this.popupOptions.width = '30%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeHazardPicturePopup;
    },
    closeHazardPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    openImprPicture(props) {
      this.popupOptions.title = 'LBL0001181'; // 개선 사진
      this.popupOptions.param = {
        ram4mAssessScenarioId: props.row.ram4mAssessScenarioId,
        sopImprovementIds: props.row.sopImprovementIds,
        ram4mAssessImprIds: props.row.ram4mAssessImprIds,
        disabled: true
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/4m/action/4mScenarioImprPicture.vue'}`);
      this.popupOptions.width = '30%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeScenarioImprPicturePopup;
    },
    closeScenarioImprPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>